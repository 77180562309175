import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import styles from './VoucherCampaignSlideSkeleton.module.scss';

const VoucherCampaignSlideSkeleton: FC = () => {
  return (
    <div className={styles.container} data-testid="voucher-campaign-slide-skeleton">
      <div className={styles.topSection}>
        <section className={styles.leftPart}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="80px"
            className={styles.valueSkeleton}
          />
        </section>

        <section className={styles.rightPart}>
          <Skeleton
            variant="text"
            width="80%"
            height="14px"
            className={styles.descriptionSkeleton}
          />
          <Skeleton
            variant="text"
            width="80%"
            height="14px"
            className={styles.descriptionSkeleton}
          />
          <Skeleton
            variant="text"
            width="80%"
            height="14px"
            className={styles.descriptionSkeleton}
          />
          <Skeleton
            variant="text"
            width="80%"
            height="14px"
            className={styles.descriptionSkeleton}
          />
        </section>
      </div>

      <div className={styles.middleDivider}></div>

      <div className={styles.buttonContainer}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="40px"
          className={styles.buttonSkeleton}
        />
      </div>
    </div>
  );
};

export default VoucherCampaignSlideSkeleton;
