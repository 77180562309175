import { Text } from '@hse24/shared-components';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import StyledDialog from '../../../../../../components/StyledDialog/StyledDialog';
import t from '../../../../../../constants/translation';
import { EUROPE_BERLIN_TIMEZONE } from '../../../../../../shows/constants';
import { getVoucherValue } from '../../../../../../utils/formatters/voucherFormatter/voucherFormatter';
import {
  CMSVoucherResponse,
  VoucherRestrictionMode,
} from '../../../../../../vouchers/model/voucher';
import { excludeBooksSkus, formatGroupedRestrictions } from '../../../../../utils/voucherUtils';
import styles from './VoucherLegalTextPopup.module.scss';

interface VoucherLegalTextPopupProps {
  isVoucherLegalTextPopupOpen: boolean;
  handleClose: (isOpen: boolean) => void;
  voucher: CMSVoucherResponse;
}

const VoucherLegalTextPopup = ({
  isVoucherLegalTextPopupOpen,
  handleClose,
  voucher,
}: VoucherLegalTextPopupProps) => {
  const DATE_FORMAT = 'DD.MM.YYYY HH:mm';
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const formatVoucherValidityDate = (
    <>
      <Text.B1 className={styles.value} data-testid="voucher-validity-start">
        {`${dayjs(voucher.validityStartTime).tz(EUROPE_BERLIN_TIMEZONE).format(DATE_FORMAT)} Uhr `}
      </Text.B1>
      <Text.B1 className={styles.field} data-testid="voucher-validity-bis">
        <span className={styles.bis}>{'bis'}</span>
      </Text.B1>
      <br />
      <Text.B1 className={styles.value} data-testid="voucher-validity-end">
        {`${dayjs(voucher.validityEndTime).tz(EUROPE_BERLIN_TIMEZONE).format(DATE_FORMAT)} Uhr `}
      </Text.B1>
    </>
  );

  const includedRestrictions = excludeBooksSkus(
    voucher.restrictions.filter(restriction => restriction.mode === VoucherRestrictionMode.INCLUDE)
  );

  const excludedRestrictions = excludeBooksSkus(
    voucher.restrictions.filter(restriction => restriction.mode === VoucherRestrictionMode.EXCLUDE)
  );

  const fullLegalText = voucher.germanLegalText
    ? voucher.booksExcluded
      ? `${t.creators.voucher.booksExcluded}, ${voucher.germanLegalText}`
      : voucher.germanLegalText
    : '';

  const close = () => {
    handleClose(false);
  };
  return (
    <StyledDialog
      close={close}
      dialogProps={{
        open: isVoucherLegalTextPopupOpen,
        maxWidth: 'md',
        PaperProps: {
          className: styles.wrapper,
        },
      }}
      fullWidth={true}
      testId="voucher-legal-text-popup"
      classes={{
        header: styles.header,
        body: styles.componentBody,
        closeIcon: styles.closeButton,
      }}
      header={
        <>
          <div data-testid="voucher-legal-text-popup-header">
            <section className={styles.grabber} data-testid="voucher-grabber" onClick={close} />
            <div className={styles.headerContent}>
              <Text.J1 className={styles.title}>{t.creators.voucher['Voucher-Details']}</Text.J1>
            </div>
            <div className={styles.divider} />
          </div>
        </>
      }
      body={
        <>
          <div className={styles.content} data-testid="voucher-legal-text-popup-body">
            {voucher.code && (
              <div data-testid="voucher-code-section">
                <Text.B1 className={styles.field}>{'Code'}</Text.B1>
                <Text.B1 className={styles.value}>{voucher.code}</Text.B1>
              </div>
            )}

            {!!getVoucherValue(voucher) && voucher.value > 0 && (
              <div data-testid="voucher-discount-section">
                <Text.B1 className={styles.field}>{t.creators.voucher.Discount}</Text.B1>
                <Text.B1 className={styles.value}>{getVoucherValue(voucher)}</Text.B1>
              </div>
            )}

            {formatVoucherValidityDate && (
              <div data-testid="voucher-validity-section">
                <Text.B1 className={styles.field}>{t.creators.voucher.validity}</Text.B1>
                <Text.B1 className={styles.value}>{formatVoucherValidityDate}</Text.B1>
              </div>
            )}

            <div data-testid="voucher-included-restrictions">
              <Text.B1 className={styles.field}>{t.creators.voucher.For}</Text.B1>
              <Text.B1 className={styles.value} style={{ whiteSpace: 'pre-wrap' }}>
                {includedRestrictions.length > 0
                  ? formatGroupedRestrictions(includedRestrictions)
                  : t.creators.voucher['All products available immediately']}
              </Text.B1>
            </div>

            {!!excludedRestrictions.length && (
              <div data-testid="voucher-excluded-restrictions">
                <Text.B1 className={styles.field}>{t.creators.voucher.NotFor}</Text.B1>
                <Text.B1 className={styles.value}>
                  {excludedRestrictions.map(restriction => restriction.name).join(', ')}
                </Text.B1>
              </div>
            )}

            {!!fullLegalText && (
              <Text.BodyMicro className={styles.legalText} data-testid="voucher-legal-text">
                <span dangerouslySetInnerHTML={{ __html: fullLegalText }}></span>
              </Text.BodyMicro>
            )}
          </div>
        </>
      }
    />
  );
};

export default VoucherLegalTextPopup;
