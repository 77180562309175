import { camelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import Config from '../../config/config';
import { featureFlagValue } from '../../config/featureFlagConfig';
import { AvailableFlag } from '../../config/FeatureFlags';
import { BasketItemsResponse } from '../../shows/api/showsRequestResponse';
import { CustomerCreditAmountResponse } from './CustomerCreditAmountResponse';
import { InvoiceResponse } from './InvoiceResponse';

const defaultBaseUrl = Config.env.basketServiceBaseUrl;
const csBaseUrl = Config.env.customerServiceBaseUrl;
const ohsBaseUrl = Config.env.orderHistoryServiceUrl;

const basketClient = (baseUrl: string) => authClient.createClient(baseUrl);
const csClient = (baseUrl: string) => authClient.createClient(baseUrl);
const ohsClient = (baseUrl: string) => authClient.createClient(baseUrl);

const fetchBasketItems = async (baseUrl: string = defaultBaseUrl): Promise<BasketItemsResponse> => {
  const axiosResponse = await basketClient(baseUrl).get('/basket');
  return camelizeKeys(axiosResponse.data) as unknown as BasketItemsResponse;
};

const getCreditAmount = async (
  baseUrl: string = csBaseUrl
): Promise<CustomerCreditAmountResponse> => {
  if (!featureFlagValue(AvailableFlag.isOrderBudgetEnabled)) {
    return { creditAmount: undefined };
  }
  const axiosResponse = await RefreshTokenUtil.wrap(() =>
    csClient(baseUrl).get(`/user/credit-amount?disable_cache=true`, {
      withCredentials: true,
    })
  );
  return camelizeKeys(axiosResponse.data) as unknown as CustomerCreditAmountResponse;
};

const getOpenInvoices = async (baseUrl: string = ohsBaseUrl): Promise<InvoiceResponse> => {
  const axiosResponse = await RefreshTokenUtil.wrap(() =>
    ohsClient(baseUrl).get(`/invoices/open?disable_cache=true`, {
      withCredentials: true,
    })
  );
  return camelizeKeys(axiosResponse.data) as unknown as InvoiceResponse;
};

export const basketApi = {
  fetchBasketItems,
  getCreditAmount,
  getOpenInvoices,
};
