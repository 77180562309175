import Config from '../../config/config';
import t from '../../constants/translation';
import { formatDate, getDayOfTheWeek } from '../../utils/formatters/dateFormatter/dateFormatter';
import {
  CMSVoucherResponse,
  VoucherRestrictionMode,
  VoucherRestrictionType,
} from '../../vouchers/model/voucher';

export const getDynamicVoucherDescription = (voucher: CMSVoucherResponse): string => {
  const dateText = t.creators.voucher.validUntil
    .replace('{day}', getDayOfTheWeek(voucher.validityEndTime))
    .replace('{date}', formatDate(voucher.validityEndTime));

  const includes = voucher.restrictions.filter(r => r.mode === VoucherRestrictionMode.INCLUDE);
  const excludes = voucher.restrictions.filter(r => r.mode === VoucherRestrictionMode.EXCLUDE);

  let restrictionsText = '';

  switch (true) {
    /**
     * CASE 1
     * The restriction array has only EXCLUDE restrictions and zero INCLUDE restrictions.
     */
    case includes.length === 0 && excludes.length > 0:
      restrictionsText = t.creators.voucher['on the entire range'];
      break;

    /**
     * CASE 2
     * The restriction array has INCLUDE restrictions, and *all* those INCLUDE restrictions
     * share the same VoucherRestrictionType.
     */
    case includes.length > 0 && allIncludesShareSameType(includes):
      {
        const restrictionTypeLabel = getVoucherRestrictionTypeLabel(
          includes[0].type,
          includes.length > 1
        );

        const names = includes.map(r => r.name);
        const namesFormatted = formatNamesWithAnd(names);

        restrictionsText = `auf die ${restrictionTypeLabel} ${namesFormatted}`;
      }
      break;

    /**
     * CASE 3
     * The restriction array has INCLUDE restrictions, but they do NOT all share the same type.
     * We group by type, build partial strings, and then join them with "und".
     *
     * e.g.,
     * "auf die Marken BrandA & BrandB und Kategorien Damenmode & Herrenmode"
     */
    case includes.length > 0 && !allIncludesShareSameType(includes):
      {
        const groupedIncludes = groupByRestrictionType(includes);

        const typeStrings = Object.entries(groupedIncludes).map(([type, names]) => {
          const label = getVoucherRestrictionTypeLabel(
            type as VoucherRestrictionType,
            names.length > 1
          );
          const formattedNames = formatNamesWithAnd(names);
          return `${label} ${formattedNames}`;
        });

        // Join each block with ' und '
        // e.g. "Marken Brand1, Brand2 & Brand3 und Kategorien Cat1 & Cat2"
        restrictionsText = `auf die ${typeStrings.join(' und ')}`;
      }
      break;

    default:
      restrictionsText = '';
      break;
  }

  // If there's no restrictions text, we do not append ", "
  if (!restrictionsText) {
    return dateText;
  }

  return `${dateText}, ${restrictionsText}`;
};

export const formatGroupedRestrictions = (
  restrictions: CMSVoucherResponse['restrictions']
): string => {
  const groupedRestrictions = groupByRestrictionType(restrictions);

  return Object.entries(groupedRestrictions)
    .map(([type, names]) => {
      const label = getVoucherRestrictionTypeLabel(
        type as VoucherRestrictionType,
        names.length > 1
      );
      return `${label}: ${names.join(', ')}`;
    })
    .join('\n');
};

export const excludeBooksSkus = (restrictions: CMSVoucherResponse['restrictions']) => {
  const filteredRestrictions = restrictions.filter(
    restriction =>
      !(
        restriction.type === VoucherRestrictionType.PRODUCT &&
        restriction.name &&
        Config.env.vouchers.excludedBooksSkus.includes(restriction.name)
      )
  );
  return filteredRestrictions;
};

const getVoucherRestrictionTypeLabel = (type: VoucherRestrictionType, many = false) => {
  switch (type) {
    case VoucherRestrictionType.BRAND:
      return many ? 'Marken' : 'Marke';
    case VoucherRestrictionType.CATEGORY:
      return many ? 'Kategorien' : 'Kategorie';
    case VoucherRestrictionType.PRODUCT:
      return many ? 'Produkte' : 'Produkt';
    case VoucherRestrictionType.PRODUCT_PRICE_LABEL:
      return many ? 'Preislabels' : 'Preislabel';
    default:
      return '';
  }
};

const formatNamesWithAnd = (names: string[]): string => {
  const trimmed = names.map(n => n.trim()).filter(Boolean);
  if (trimmed.length === 0) return '';
  if (trimmed.length === 1) return trimmed[0];
  if (trimmed.length === 2) return `${trimmed[0]} , ${trimmed[1]}`;
  return trimmed.slice(0, -1).join(', ') + ' & ' + trimmed[trimmed.length - 1];
};

const groupByRestrictionType = (includes: CMSVoucherResponse['restrictions']) => {
  return includes.reduce<Record<string, string[]>>((acc, restriction) => {
    if (!acc[restriction.type]) {
      acc[restriction.type] = [];
    }
    acc[restriction.type].push(restriction.name);
    return acc;
  }, {});
};

const allIncludesShareSameType = (includes: CMSVoucherResponse['restrictions']): boolean => {
  if (includes.length === 0) return false;
  const firstType = includes[0].type;
  return includes.every(r => r.type === firstType);
};
