import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Category } from '../model/category';
import { Facet } from '../model/facet';
import { Locale } from '../model/locale';
import { IPage } from '../model/page';
import { Paging } from '../model/paging';
import { ProductTileInfo } from '../model/productTileInfo';
import { SearchParams } from '../model/searchParams';
import { SearchResponse } from '../model/searchResponse';
import { TSortItem } from '../model/sortItem';
import { mapToCategories } from '../utils/categoriesMapper';
import { mapToFacets } from '../utils/facetsMapper';
import { addPages, updatePages } from '../utils/pagesUtils';
import { mapToPaging, updatePaging } from '../utils/pagingMapper';
import { normalizeProducts } from '../utils/productTileInfoUtils';
import { updateSearchParams } from '../utils/searchParamsUtils';
import { mapSortItems } from '../utils/sortItemsUtils';

export interface ProductsSelection {
  loading: boolean;
  selectedProducts: ProductTileInfo[];
  productsResponse: ProductTileInfo[];
  totalOrders: number;
  isOpen: boolean;
}

export interface ProductsState {
  loading: boolean;
  query: string;
  categories: Category[];
  pages: IPage[];
  foundProducts: number;
  searchParams?: SearchParams;
  facets?: Facet[];
  paging: Paging;
  sortItems: TSortItem[];
  isFilterModalOpen: boolean;
  productsSelection: ProductsSelection;
}

export const productsInitialState: ProductsState = {
  loading: true,
  query: '',
  categories: [],
  pages: [],
  foundProducts: 0,
  searchParams: undefined,
  facets: [],
  paging: {
    prevLink: null,
    nextLink: null,
    maxVisitedPage: null,
    minVisitedPage: null,
    currentPage: 1,
    pageCount: 1,
  },
  sortItems: [],
  isFilterModalOpen: false,
  productsSelection: {
    isOpen: false,
    loading: false,
    selectedProducts: [],
    productsResponse: [],
    totalOrders: 0,
  },
};

export const fetchFactFinderResults = createAction<{ query: string }>(
  'products/fetchFactFinderResults'
);

export const fetchProductsSelectionSearch = createAction<{
  query: string;
  canAddNotFoundProducts?: boolean;
}>('products/fetchProductsSelectionSearch');

export const fetchOrdersProductsSelectionSearch = createAction(
  'products/fetchOrdersProductsSelectionSearch'
);

export const productsSlice = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    setFactFinderLoading: (
      state: ProductsState,
      action: PayloadAction<{ isNewSearch: boolean }>
    ) => {
      state.loading = true;
      state.pages = action.payload.isNewSearch ? [] : state.pages;
    },
    fetchFactFinderResultsSuccess: (
      state: ProductsState,
      action: PayloadAction<{
        searchResponse: SearchResponse;
        query: string;
        isNewSearch: boolean;
        locale: Locale;
      }>
    ) => {
      state.loading = false;
      state.query = action.payload.query;
      state.categories = mapToCategories(action.payload.searchResponse.facets);
      state.pages = addPages(
        state.pages,
        action.payload.isNewSearch,
        action.payload.searchResponse.paging.currentPage,
        normalizeProducts(action.payload.searchResponse.hits)
      );
      state.foundProducts = action.payload.searchResponse.totalHits;
      state.searchParams = action.payload.searchResponse.searchParams;
      state.facets = mapToFacets(action.payload.searchResponse.facets);
      state.paging = mapToPaging(
        action.payload.searchResponse.paging,
        state.paging,
        action.payload.isNewSearch
      );
      state.sortItems = mapSortItems(action.payload.searchResponse.sortItems);
    },
    updateFactFinderResultsSuccess: (
      state: ProductsState,
      action: PayloadAction<{
        query: string;
        requestedPage: number;
      }>
    ) => {
      state.loading = false;
      state.query = action.payload.query;
      state.pages = updatePages(state.pages, action.payload.requestedPage);
      state.searchParams = updateSearchParams(action.payload.requestedPage, state.searchParams);
      state.paging = updatePaging(state.paging, action.payload.requestedPage);
    },
    fetchFactFinderResultsError: () => {
      return { ...productsInitialState, loading: false };
    },
    toggleFilterModal: state => {
      state.isFilterModalOpen = !state.isFilterModalOpen;
    },
    setProductsSelectionResponse: (state, action: PayloadAction<ProductTileInfo[]>) => {
      state.productsSelection.productsResponse = action.payload;
    },
    setTotalOrders: (state, action: PayloadAction<number>) => {
      state.productsSelection.totalOrders = action.payload;
    },
    addToProductsSelectionSelectedProducts: (state, action: PayloadAction<ProductTileInfo[]>) => {
      state.productsSelection.selectedProducts = action.payload.concat(
        state.productsSelection.selectedProducts
      );
    },
    removeFromProductsSelectionSelectedProducts: (
      state,
      action: PayloadAction<ProductTileInfo>
    ) => {
      state.productsSelection.selectedProducts = state.productsSelection.selectedProducts.filter(
        e => e.baseProductNo !== action.payload.baseProductNo
      );
    },
    setProductsSelectionLoading: (state, action: PayloadAction<boolean>) => {
      state.productsSelection.loading = action.payload;
    },
    clearProductsSelection: state => {
      state.productsSelection.selectedProducts = [];
    },
    clearProductsSelectionResponse: state => {
      state.productsSelection.productsResponse = [];
    },
    openProductsSelection: state => {
      state.productsSelection.isOpen = true;
    },
    closeProductsSelection: state => {
      state.productsSelection.isOpen = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFactFinderResults, state => {
      state.loading = true;
    });
  },
});

export const {
  setFactFinderLoading,
  fetchFactFinderResultsSuccess,
  updateFactFinderResultsSuccess,
  fetchFactFinderResultsError,
  toggleFilterModal,
  setProductsSelectionResponse,
  setProductsSelectionLoading,
  addToProductsSelectionSelectedProducts,
  removeFromProductsSelectionSelectedProducts,
  clearProductsSelection,
  clearProductsSelectionResponse,
  openProductsSelection,
  closeProductsSelection,
  setTotalOrders,
} = productsSlice.actions;

export const selectProductListProps = (state: RootState) => {
  return {
    loading: state.products.loading,
    pages: state.products.pages,
    facets: state.products.facets || [],
    foundProducts: state.products.foundProducts,
    searchParams: state.products.searchParams,
    paging: state.products.paging,
    sortItems: state.products.sortItems,
    categories: state.products.categories,
  };
};

export const selectIsFiltersModalOpen = (state: RootState) => !!state.products?.isFilterModalOpen;
export const selectProductSelection = (state: RootState) => state.products.productsSelection;

export default productsSlice.reducer;
