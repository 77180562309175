export enum VoucherRestrictionMode {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

export enum VoucherRestrictionType {
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  PRODUCT = 'PRODUCT',
  PRODUCT_PRICE_LABEL = 'PRODUCT_PRICE_LABEL',
}

export enum VoucherType {
  VALUE = 'VALUE',
  PERCENTAGE = 'PERCENTAGE',
  FREE_SHIPPING = 'FREE_SHIPPING',
}

export const UnknownVoucherType = 'UNKNOWN';

export interface VoucherRestriction {
  name: string;
  type: VoucherRestrictionType;
  mode: VoucherRestrictionMode;
  baseProductNo?: string;
  path?: string;
}

export interface Voucher {
  code: string;
  minValue: number;
  maxValue?: number;
  validityStartTime: string;
  validityEndTime: string;
  type: VoucherType;
  value: number;
  restrictions: VoucherRestriction[];
  germanLegalText?: string;
}

export interface CMSVoucherResponse {
  id: string;
  code: string;
  type: VoucherType;
  value: number;
  minValue: number;
  maxValue?: number;
  validityStartTime: string;
  validityEndTime: string;
  restrictions: VoucherRestriction[];
  germanLegalText?: string;
  booksExcluded?: boolean;
}

export interface VoucherResponse {
  voucherType: VoucherType | typeof UnknownVoucherType;
  voucherValue?: number;
}
