import { Chat, FavoriteBorder, PeopleOutline, PermIdentity, Share } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Audience, Platform } from '../../../common/types';
import AudienceBadge from '../../../components/AudienceBadge/AudienceBadge';
import { displayName } from '../../../creators/model/creator';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import {
  germanFormat,
  germanMoneyFormat,
  germanPercentFormat,
} from '../../../utils/formatters/germanFormat/germanFormats';
import { EUROPE_BERLIN_TIMEZONE, START_SHOW_FORMAT } from '../../constants';
import { Currency, ShowTotalAndLivePerformance, StreamedBy } from '../../model/shows';
import IconCard from '../IconCard/IconCard';
import StreamedByWithStreamNameCell from '../StreamedByWithStreamNameCell/StreamedByWithStreamNameCell';
import VoucherBadge from '../VoucherBadge/VoucherBadge';

interface CreateColumnsParams {
  onlyLivePerformance: boolean;
  platform: Platform[];
  adminPastShowsQueryData?: {
    audience?: Audience[];
  };
  getViewsFromPerformanceByAudienceAndSource: (performance: ShowTotalAndLivePerformance) => number;
  getViewersFromPerformanceByAudienceAndSource: (
    performance: ShowTotalAndLivePerformance
  ) => number;
  isPlatform: (selectedPlatform: Platform) => boolean | undefined;
  isTotalShouldBeDisplayed: () => boolean;
}

export function createAdminPastShowsTableColumns(params: CreateColumnsParams): GridColDef[] {
  const {
    onlyLivePerformance,
    getViewsFromPerformanceByAudienceAndSource,
    getViewersFromPerformanceByAudienceAndSource,
    isPlatform,
    isTotalShouldBeDisplayed,
  } = params;

  return [
    {
      field: 'streamedBy',
      headerName: 'Stream-Typ',
      minWidth: 150,
      hide: true,
      valueGetter: ({ value }) => (value === StreamedBy.HSE ? 'HSE' : 'Creator'),
    },
    {
      field: 'title',
      headerName: 'Stream',
      minWidth: 250,
      renderCell: ({ row }) => (
        <StreamedByWithStreamNameCell
          streamedBy={row.streamedBy}
          title={row.title}
          isPublished={row.isPublished}
        />
      ),
    },
    {
      field: 'startedAt',
      headerName: 'Datum',
      minWidth: 145,
      valueFormatter: ({ value }) =>
        dayjs(value).tz(EUROPE_BERLIN_TIMEZONE).format(START_SHOW_FORMAT),
    },
    {
      field: 'audience',
      headerName: 'Audience',
      minWidth: 145,
      renderCell: value => <AudienceBadge audience={value.row.audience} />,
    },
    {
      field: 'streamId',
      headerName: 'Stream-ID',
      width: 200,
      valueGetter: params => params.row.id,
      hide: true,
      sortable: false,
    },
    {
      field: 'streamerId',
      headerName: 'Streamer-ID',
      width: 200,
      valueGetter: params => params.row.creator.id,
      hide: true,
      sortable: false,
    },
    {
      field: 'creatorName',
      headerName: 'Creator',
      width: 200,
      valueGetter: params => params.row.creator.name,
    },
    {
      field: 'creatorRole',
      headerName: 'Rolle',
      width: 100,
      valueGetter: params => params.row.creator.role,
      valueFormatter: ({ value }) => displayName(value),
      hide: true,
    },
    {
      field: 'durationInSeconds',
      headerName: 'Dauer',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: `streamFloorSaleLive`,
      headerName: `Umsatz ohne Steuer (Live)`,
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.streamTotal,
      hide: !(onlyLivePerformance && isTotalShouldBeDisplayed()),
    },
    {
      field: `webFloorSalesLive`,
      headerName: 'Umsatz Web (Live)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.webFloorSales,
      hide: !(onlyLivePerformance && isPlatform(Platform.WEB)),
    },
    {
      field: `appFloorSalesLive`,
      headerName: 'Umsatz App (Live)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.appFloorSaleTotal,
      hide: !(onlyLivePerformance && isPlatform(Platform.APP)),
    },
    {
      field: `iosFloorSalesLive`,
      headerName: 'Umsatz iOS (Live)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.iosFloorSales,
      hide: !(onlyLivePerformance && isPlatform(Platform.IOS)),
    },
    {
      field: `androidFloorSalesLive`,
      headerName: 'Umsatz Android (Live)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.androidFloorSales,
      hide: !(onlyLivePerformance && isPlatform(Platform.ANDROID)),
    },
    {
      field: `streamFloorSaleTotal`,
      headerName: 'Umsatz ohne Steuer (Stream)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.streamTotal,
      hide: !(!onlyLivePerformance && isTotalShouldBeDisplayed()),
    },
    {
      field: `webFloorSalesTotal`,
      headerName: 'Umsatz Web (Stream)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.webFloorSales,
      hide: !(!onlyLivePerformance && isPlatform(Platform.WEB)),
    },
    {
      field: `appFloorSalesTotal`,
      headerName: 'Umsatz App (Stream)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.appFloorSaleTotal,
      hide: !(!onlyLivePerformance && isPlatform(Platform.APP)),
    },
    {
      field: `iosFloorSalesTotal`,
      headerName: 'Umsatz iOS (Stream)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.iosFloorSales,
      hide: !(!onlyLivePerformance && isPlatform(Platform.IOS)),
    },
    {
      field: `androidFloorSalesTotal`,
      headerName: 'Umsatz Android (Stream)',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.androidFloorSales,
      hide: !(!onlyLivePerformance && isPlatform(Platform.ANDROID)),
    },
    {
      field: `streamOrdersLive`,
      headerName: 'Bestellungen (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      hide: !(onlyLivePerformance && isTotalShouldBeDisplayed()),
    },
    {
      field: `webOrdersCountLive`,
      headerName: 'Bestellungen Web (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(onlyLivePerformance && isPlatform(Platform.WEB)),
      valueGetter: params => params.row.livePerformance.ordersOverview.webOrdersCount,
    },
    {
      field: `appOrdersCountLive`,
      headerName: 'Bestellungen App (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(onlyLivePerformance && isPlatform(Platform.APP)),
      valueGetter: params =>
        params.row.livePerformance.ordersOverview.androidOrdersCount +
        params.row.livePerformance.ordersOverview.iosOrdersCount,
    },
    {
      field: `iosOrdersCountLive`,
      headerName: 'Bestellungen iOS (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(onlyLivePerformance && isPlatform(Platform.IOS)),
      valueGetter: params => params.row.livePerformance.ordersOverview.iosOrdersCount,
    },
    {
      field: `androidOrdersCountLive`,
      headerName: 'Bestellungen Android (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(onlyLivePerformance && isPlatform(Platform.ANDROID)),
      valueGetter: params => params.row.livePerformance.ordersOverview.androidOrdersCount,
    },
    {
      field: `streamOrdersTotal`,
      headerName: 'Bestellungen (Stream)',
      width: 180,
      align: 'right',
      headerAlign: 'right',
      hide: !(!onlyLivePerformance && isTotalShouldBeDisplayed()),
    },
    {
      field: `voucherCode`,
      headerName: 'Gutschein',
      width: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <VoucherBadge voucherCode={params.row.voucherCode}></VoucherBadge>,
      valueGetter: ({ value }) => (value != undefined ? value : '-'),
    },
    {
      field: `voucherOrdersTotal`,
      headerName: 'Bestellungen (Gutschein)',
      width: 180,
      align: 'right',
      headerAlign: 'right',
      hide: !(!onlyLivePerformance && isTotalShouldBeDisplayed()),
      valueGetter: params => params.row.totalPerformance.ordersOverview.voucherOrdersCount,
    },
    {
      field: `voucherFloorSaleTotal`,
      headerName: 'Umsatz ohne Steuer (Gutschein)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params =>
        params.row.totalPerformance.sales.voucherFloorSaleTotal ?? {
          amount: 0,
          currency: Currency.EUR,
        },
      hide: !(!onlyLivePerformance && isTotalShouldBeDisplayed()),
    },
    {
      field: `viewersLive`,
      headerName: 'Zuschauer (Live)',
      width: 155,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Zuschauer"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params =>
        getViewersFromPerformanceByAudienceAndSource(params.row.livePerformance),
      hide: !onlyLivePerformance,
    },
    {
      field: `liveClassicViewersFromApp`,
      headerName: 'Classic App Zuschauer (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic App Zuschauer (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewersOverview.classicAppViewers,
      hide: true,
    },
    {
      field: `liveClassicViewersFromWeb`,
      headerName: 'Classic Web Zuschauer (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Web Zuschauer (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewersOverview.classicWebViewers,
      hide: true,
    },
    {
      field: `liveClassicViewersFromIos`,
      headerName: 'Classic iOS Zuschauer (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic iOS Zuschauer (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewersOverview.classicIosViewers,
      hide: true,
    },
    {
      field: `liveClassicViewersFromAndroid`,
      headerName: 'Classic Android Zuschauer (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Android Zuschauer (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewersOverview.classicAndroidViewers,
      hide: true,
    },
    {
      field: `viewersTotal`,
      headerName: 'Zuschauer (Stream)',
      width: 155,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Zuschauer"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params =>
        getViewersFromPerformanceByAudienceAndSource(params.row.totalPerformance),
      hide: onlyLivePerformance,
    },
    {
      field: `totalClassicViewersFromApp`,
      headerName: 'Classic App Zuschauer (Stream)',
      width: 270,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic App Zuschauer (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewersOverview.classicAppViewers,
      hide: true,
    },
    {
      field: `totalClassicViewersFromWeb`,
      headerName: 'Classic Web Zuschauer (Stream)',
      width: 270,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Web Zuschauer (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewersOverview.classicWebViewers,
      hide: true,
    },
    {
      field: `totalClassicViewersFromIos`,
      headerName: 'Classic iOS Zuschauer (Stream)',
      width: 270,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic iOS Zuschauer (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewersOverview.classicIosViewers,
      hide: true,
    },
    {
      field: `totalClassicViewersFromAndroid`,
      headerName: 'Classic Android Zuschauer (Stream)',
      width: 270,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Android Zuschauer (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewersOverview.classicAndroidViewers,
      hide: true,
    },
    {
      field: `floorSalePerViewerLive`,
      headerName: 'Umsatz ohne Steuer/Zuschauer (Live)',
      width: 280,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.perVisitor,
      hide: true,
    },
    {
      field: `floorSalePerViewerTotal`,
      headerName: 'Umsatz ohne Steuer/Zuschauer (Stream)',
      width: 300,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.perVisitor,
      hide: true,
    },
    {
      field: `timeWatchPerViewerLive`,
      width: 245,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Watch Time/Zuschauer (Live)',
      valueFormatter: ({ value }) => formatTime(value),
      valueGetter: params => params.row.livePerformance.timeWatched?.perViewer,
      hide: !onlyLivePerformance,
    },
    {
      field: `timeWatchPerViewerTotal`,
      width: 245,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Watch Time/Zuschauer (Stream)',
      valueFormatter: ({ value }) => formatTime(value),
      valueGetter: params => params.row.totalPerformance.timeWatched?.perViewer,
      hide: onlyLivePerformance,
    },
    {
      field: `webOrdersCountTotal`,
      headerName: 'Bestellungen Web (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(!onlyLivePerformance && isPlatform(Platform.WEB)),
      valueGetter: params => params.row.totalPerformance.ordersOverview.webOrdersCount,
    },
    {
      field: `appOrdersCountTotal`,
      headerName: 'Bestellungen App (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(!onlyLivePerformance && isPlatform(Platform.APP)),
      valueGetter: params =>
        params.row.totalPerformance.ordersOverview.androidOrdersCount +
        params.row.totalPerformance.ordersOverview.iosOrdersCount,
    },
    {
      field: `iosOrdersCountTotal`,
      headerName: 'Bestellungen iOS (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(!onlyLivePerformance && isPlatform(Platform.IOS)),
      valueGetter: params => params.row.totalPerformance.ordersOverview.iosOrdersCount,
    },
    {
      field: `androidOrdersCountTotal`,
      headerName: 'Bestellungen Android (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      hide: !(!onlyLivePerformance && isPlatform(Platform.ANDROID)),
      valueGetter: params => params.row.totalPerformance.ordersOverview.androidOrdersCount,
    },
    {
      field: 'streamConversionLive',
      headerName: 'Conversion (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.streamConversionLive * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.streamConversionLive * 100),
      hide:
        !onlyLivePerformance ||
        (onlyLivePerformance &&
          (isPlatform(Platform.IOS) ||
            isPlatform(Platform.ANDROID) ||
            isPlatform(Platform.APP) ||
            isPlatform(Platform.WEB))),
    },
    {
      field: 'conversionIosLive',
      headerName: 'Conversion iOS (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.iosConversionLive * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.iosConversionLive * 100),
      hide: !(onlyLivePerformance && isPlatform(Platform.IOS)),
    },
    {
      field: 'conversionAndroidLive',
      headerName: 'Conversion Android (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.androidConversionLive * 100),
      valueGetter: params =>
        germanFormat(params.row.conversionOverview.androidConversionLive * 100),
      hide: !(onlyLivePerformance && isPlatform(Platform.ANDROID)),
    },
    {
      field: 'conversionAppLive',
      headerName: 'Conversion App (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.appConversionLive * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.appConversionLive * 100),
      hide: !(onlyLivePerformance && isPlatform(Platform.APP)),
    },
    {
      field: 'conversionWebLive',
      headerName: 'Conversion Web (Live)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.webConversionLive * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.webConversionLive * 100),
      hide: !(onlyLivePerformance && isPlatform(Platform.WEB)),
    },
    {
      field: 'streamConversionTotal',
      headerName: 'Conversion (Stream)',
      width: 190,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.streamConversionTotal * 100),
      valueGetter: params =>
        germanFormat(params.row.conversionOverview.streamConversionTotal * 100),
      hide:
        onlyLivePerformance ||
        (!onlyLivePerformance &&
          (isPlatform(Platform.IOS) ||
            isPlatform(Platform.ANDROID) ||
            isPlatform(Platform.APP) ||
            isPlatform(Platform.WEB))),
    },
    {
      field: 'conversionIosTotal',
      headerName: 'Conversion iOS (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.iosConversionTotal * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.iosConversionTotal * 100),
      hide: !(!onlyLivePerformance && isPlatform(Platform.IOS)),
    },
    {
      field: 'conversionAndroidTotal',
      headerName: 'Conversion Android (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.androidConversionTotal * 100),
      valueGetter: params =>
        germanFormat(params.row.conversionOverview.androidConversionTotal * 100),
      hide: !(!onlyLivePerformance && isPlatform(Platform.ANDROID)),
    },
    {
      field: 'conversionAppTotal',
      headerName: 'Conversion App (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.appConversionTotal * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.appConversionTotal * 100),
      hide: !(!onlyLivePerformance && isPlatform(Platform.APP)),
    },
    {
      field: 'conversionWebTotal',
      headerName: 'Conversion Web (Stream)',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderCell: params =>
        germanPercentFormat(params.row.conversionOverview.webConversionTotal * 100),
      valueGetter: params => germanFormat(params.row.conversionOverview.webConversionTotal * 100),
      hide: !(!onlyLivePerformance && isPlatform(Platform.WEB)),
    },
    {
      field: `floorSalePerMinuteLive`,
      headerName: 'Umsatz ohne Steuer/Minute (Live)',
      width: 280,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.livePerformance.sales.perMinute,
      hide: !onlyLivePerformance,
    },
    {
      field: `floorSalePerMinuteTotal`,
      headerName: 'Umsatz ohne Steuer/Minute (Stream)',
      width: 290,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      valueFormatter: ({ value }) => germanFormat(value.amount),
      valueGetter: params => params.row.totalPerformance.sales.perMinute,
      hide: onlyLivePerformance,
    },
    {
      field: `viewsLive`,
      headerName: 'Aufrufe (Live)',
      width: 155,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Aufrufe"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => getViewsFromPerformanceByAudienceAndSource(params.row.livePerformance),
      hide: true,
    },
    {
      field: `liveClassicViewsFromApp`,
      headerName: 'Classic App Aufrufe (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic App Aufrufe (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewsOverview.classicAppViews,
      hide: true,
    },
    {
      field: `liveClassicViewsFromWeb`,
      headerName: 'Classic Web Aufrufe (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Web Aufrufe (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewsOverview.classicWebViews,
      hide: true,
    },
    {
      field: `liveClassicViewsFromIos`,
      headerName: 'Classic iOS Aufrufe (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic iOS Aufrufe (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewsOverview.classicIosViews,
      hide: true,
    },
    {
      field: `liveClassicViewsFromAndroid`,
      headerName: 'Classic Android Aufrufe (Live)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Android Aufrufe (Live)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.viewsOverview.classicAndroidViews,
      hide: true,
    },
    {
      field: `viewsTotal`,
      headerName: 'Aufrufe (Stream)',
      width: 155,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Aufrufe"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params =>
        getViewsFromPerformanceByAudienceAndSource(params.row.totalPerformance),
      hide: true,
    },
    {
      field: `totalClassicViewsFromApp`,
      headerName: 'Classic App Aufrufe (Stream)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic App Aufrufe (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewsOverview.classicAppViews,
      hide: true,
    },
    {
      field: `totalClassicViewsFromWeb`,
      headerName: 'Classic Web Aufrufe (Stream)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Web Aufrufe (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewsOverview.classicWebViews,
      hide: true,
    },
    {
      field: `totalClassicViewsFromIos`,
      headerName: 'Classic iOS Aufrufe (Stream)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic iOS Aufrufe (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewsOverview.classicIosViews,
      hide: true,
    },
    {
      field: `totalClassicViewsFromAndroid`,
      headerName: 'Classic Android Aufrufe (Stream)',
      width: 250,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Classic Android Aufrufe (Stream)"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.viewsOverview.classicAndroidViews,
      hide: true,
    },
    {
      field: `timeWatchLive`,
      width: 170,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Watch Time (Live)',
      valueFormatter: ({ value }) => formatTime(value),
      valueGetter: params => params.row.livePerformance.timeWatched?.seconds,
      hide: true,
    },
    {
      field: `timeWatchTotal`,
      width: 170,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Watch Time (Stream)',
      valueFormatter: ({ value }) => formatTime(value),
      valueGetter: params => params.row.totalPerformance.timeWatched?.seconds,
      hide: true,
    },
    {
      field: `timeWatchPerViewLive`,
      width: 245,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Watch Time/Aufruf (Live)',
      valueFormatter: ({ value }) => formatTime(value),
      valueGetter: params => params.row.livePerformance.timeWatched?.perView,
      hide: true,
    },
    {
      field: `timeWatchPerViewTotal`,
      width: 245,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Watch Time/Aufruf (Stream)',
      valueFormatter: ({ value }) => formatTime(value),
      valueGetter: params => params.row.totalPerformance.timeWatched?.perView,
      hide: true,
    },
    {
      field: `sharesLive`,
      width: 130,
      align: 'right',
      headerName: 'Shares (Live)',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Shares"
          textStyle={{ fontWeight: 'bold' }}
          icon={<Share fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.shares,
      hide: !onlyLivePerformance,
    },
    {
      field: `sharesTotal`,
      width: 130,
      align: 'right',
      headerName: 'Shares (Stream)',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Shares"
          textStyle={{ fontWeight: 'bold' }}
          icon={<Share fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.shares,
      hide: onlyLivePerformance,
    },
    {
      field: `likesLive`,
      width: 120,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Likes (Live)',
      renderHeader: () => (
        <IconCard
          text="Likes"
          textStyle={{ fontWeight: 'bold' }}
          icon={<FavoriteBorder fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.likes,
      hide: !onlyLivePerformance,
    },
    {
      field: `likesTotal`,
      width: 120,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Likes (Stream)',
      renderHeader: () => (
        <IconCard
          text="Likes"
          textStyle={{ fontWeight: 'bold' }}
          icon={<FavoriteBorder fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.likes,
      hide: onlyLivePerformance,
    },
    {
      field: `chatMessagesLive`,
      width: 125,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Chats (Live)',
      renderHeader: () => (
        <IconCard
          text="Chats"
          textStyle={{ fontWeight: 'bold' }}
          icon={<Chat fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.livePerformance.chatMessages,
      hide: !onlyLivePerformance,
    },
    {
      field: `chatMessagesTotal`,
      width: 125,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Chats (Stream)',
      renderHeader: () => (
        <IconCard
          text="Chats"
          textStyle={{ fontWeight: 'bold' }}
          icon={<Chat fontSize="small" />}
        />
      ),
      valueGetter: params => params.row.totalPerformance.chatMessages,
      hide: onlyLivePerformance,
    },
  ];
}
