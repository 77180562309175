import { call, put, takeLatest } from 'redux-saga/effects';
import { errorOccurred } from '../../notifications/state/notificationsSlice';
import { vouchersApi } from '../api/vouchersApi';
import { CMSVoucherResponse } from '../model/voucher';
import {
  fetchSuggestedVouchers,
  setSuggestedVouchers,
  setSuggestedVouchersIsLoading,
} from './vouchersSlice';

export function* fetchSuggestedVouchersHandler() {
  try {
    yield put(setSuggestedVouchersIsLoading(true));
    const response: CMSVoucherResponse[] = yield call(vouchersApi.fetchSuggestedVouchers);
    yield put(setSuggestedVouchers(response));
  } catch (e) {
    yield put(errorOccurred(e as Error));
  } finally {
    yield put(setSuggestedVouchersIsLoading(false));
  }
}

export function* watcherVouchersSagas() {
  yield takeLatest(fetchSuggestedVouchers.type, fetchSuggestedVouchersHandler);
}
