import {
  Button,
  EButtonSize,
  EButtonText,
  EButtonType,
  IconInfo,
  LabelFlag,
  Text,
} from '@hse24/shared-components';
import { FC, useState } from 'react';
import t from '../../../../../constants/translation';
import { getVoucherValue } from '../../../../../utils/formatters/voucherFormatter/voucherFormatter';
import { CMSVoucherResponse } from '../../../../../vouchers/model/voucher';
import { getDynamicVoucherDescription } from '../../../../utils/voucherUtils';
import styles from './VoucherCampaignSlide.module.scss';
import VoucherLegalTextPopup from './VoucherLegalTextPopup/VoucherLegalTextPopup';

interface VoucherCampaignSlideProps {
  voucher: CMSVoucherResponse;
}

const VoucherCampaignSlide: FC<VoucherCampaignSlideProps> = ({ voucher }) => {
  const [isModalForViewVoucherDetailsOpen, setModalForViewVoucherDetailsOpen] = useState(false);

  const onButtonClick = () => {
    setModalForViewVoucherDetailsOpen(true);
  };
  return (
    <>
      <div
        className={styles.VoucherCampaignSlider}
        data-testid="voucher-slide"
        onClick={onButtonClick}
      >
        <div className={styles.topSection}>
          <section className={styles.leftPart} data-testid="voucher-slide-title">
            <LabelFlag className={styles.value} text={getVoucherValue(voucher)} />
            <LabelFlag className={styles.valueSmall} text={voucher.code} />
          </section>

          <section className={styles.rightPart} data-testid="voucher-slide-description">
            <Text.BodyMicro className={styles.description}>
              {getDynamicVoucherDescription(voucher)}
            </Text.BodyMicro>
          </section>
        </div>

        <div className={styles.middleDivider} />

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            type={EButtonType.BLANK}
            size={EButtonSize.SMALL}
            textAlign={EButtonText.LEFT}
            onClick={onButtonClick}
            data-testid="voucher-slide-button"
          >
            <span className={styles.buttonText}>{t.creators.voucher['View voucher details']}</span>
            <IconInfo width={20} height={20} className={styles.iconText} />
          </Button>
        </div>
      </div>
      <VoucherLegalTextPopup
        voucher={voucher}
        handleClose={setModalForViewVoucherDetailsOpen}
        isVoucherLegalTextPopupOpen={isModalForViewVoucherDetailsOpen}
      />
    </>
  );
};

export default VoucherCampaignSlide;
