import { Text } from '@hse24/shared-components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import followerGainedImage from '../../../assets/follower-gained-image.png';
import heartImage from '../../../assets/heart-image.png';
import emptyVoucher from '../../../assets/screen-create-empty-voucher.png';
import Header from '../../../components/Header/Header';
import { AvailableFlag, Flag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import { CMSVoucherResponse } from '../../../vouchers/model/voucher';
import {
  fetchSuggestedVouchers,
  selectSuggestedVouchers,
  selectSuggestedVouchersIsLoading,
} from '../../../vouchers/state/vouchersSlice';
import CarouselSlide from './ContentSlider/CarouselSlide/CarouselSlide';
import carouselSlideStyles from './ContentSlider/CarouselSlide/CarouselSlide.module.scss';
import ContentCarousel from './ContentSlider/ContentCarousel';
import VoucherCampaignSlide from './ContentSlider/VoucherCampaignSlider/VoucherCampaignSlide';
import voucherSlideStyles from './ContentSlider/VoucherCampaignSlider/VoucherCampaignSlide.module.scss';
import VoucherCampaignSlideSkeleton from './ContentSlider/VoucherCampaignSlider/VoucherCampaignSliderSkeleton/VoucherCampaignSlideSkeleton';
import CreationOverview from './CreationOverview/CreationOverview';
import styles from './CreatorContentComponent.module.scss';

const CreatorContentComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSuggestedVouchers());
  }, [dispatch]);

  const suggestedVouchers = useSelector(selectSuggestedVouchers) as unknown as {
    vouchers: CMSVoucherResponse[];
  };
  const isLoading = useSelector(selectSuggestedVouchersIsLoading);

  const slidesData = [
    {
      backgroundImage: followerGainedImage,
      title: t.creators.post['Increase your reach'],
      description: t.creators.post['Gain new followers for your topics and products'],
      buttonText: t.creators.post['Create video post'],
      onButtonClick: () => history.push(routePaths.creator.addPost),
    },
    {
      backgroundImage: heartImage,
      title: t.creators.post['Inspire your fans live!'],
      description:
        t.creators.post['Interact with your fans in real time and strengthen your community'],
      buttonText: t.creators.post['Schedule a live stream'],
      onButtonClick: () => history.push(routePaths.creator.addShow),
    },
  ];

  const vouchersData = suggestedVouchers.vouchers || [];

  const NoVoucherResults = ({ text }: { text: string }) => (
    <div className={styles.voucher_no_results} data-testid="no-voucher-result-container">
      <img alt={'no voucher result'} src={emptyVoucher} height={120} width={120} />
      <Text.B3 className={styles.voucher_no_results_text}>{text}</Text.B3>
    </div>
  );

  return (
    <Flag flag={AvailableFlag.isContentCreationEnabled}>
      <Header
        title={t.creators.Create}
        showTitleOnMobile={true}
        titleTestId={'creator-content-title'}
        className={styles.creatorHeader}
      />
      <div data-testid="creator-content-container" className={styles.creator_content_container}>
        <ContentCarousel styles={carouselSlideStyles}>
          {slidesData.map((slide, index) => (
            <CarouselSlide
              key={index}
              backgroundImage={slide.backgroundImage}
              title={slide.title}
              description={slide.description}
              buttonText={slide.buttonText}
              onButtonClick={slide.onButtonClick}
            />
          ))}
        </ContentCarousel>
        <CreationOverview />
      </div>
      <div data-testid="voucher-container" className={styles.voucher_container}>
        <Text.B2 className={styles.voucher_text} data-testid="voucher-actions">
          {t.creators.voucher['Voucher-Actions']}
        </Text.B2>

        {isLoading ? (
          // Skeleton view.
          <ContentCarousel styles={voucherSlideStyles}>
            {[1, 2].map((_, i) => (
              <VoucherCampaignSlideSkeleton key={i} />
            ))}
          </ContentCarousel>
        ) : vouchersData.length > 0 ? (
          <ContentCarousel styles={voucherSlideStyles}>
            {vouchersData.map(voucher => (
              <VoucherCampaignSlide key={voucher.id} voucher={voucher} />
            ))}
          </ContentCarousel>
        ) : (
          <NoVoucherResults text={t.creators.voucher.noVoucherResults} />
        )}
      </div>
    </Flag>
  );
};

export default CreatorContentComponent;
