import { Button, EButtonType, ETag, IconCheck, Text } from '@hse24/shared-components';
import React, { useState } from 'react';
import t from '../../../constants/translation';
import styles from './ShowProductReservation.module.scss';

interface Props {
  productSku: string;
  onAdd: (sku: string) => void;
}

const ShowProductReservation = ({ productSku, onAdd }: Props) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleAddProduct = () => {
    if (productSku) {
      onAdd(productSku);
      setIsConfirmed(true);
    }
  };

  return (
    <div className={styles.wrapper} data-testid="show-product-reservation">
      <section className={styles.content} data-testid="product-reservation-content">
        <Text.Body tag={ETag.DIV} className={styles.no_result_text} data-testid="no-result-text">
          {`${t.admins.upcomingShowsOverview['No result for']} `}
          <strong>{productSku}</strong>
          {`. ${t.admins.upcomingShowsOverview['Do you still want to use this item number?']}`}
        </Text.Body>
        <Button
          key={isConfirmed ? 'confirmed' : 'unconfirmed'}
          onClick={!isConfirmed ? handleAddProduct : undefined}
          type={EButtonType.PRIMARY}
          className={isConfirmed ? styles.confirm_button_clicked : styles.confirm_button_pending}
          icon={{
            left: isConfirmed ? <IconCheck className={styles.checkmark_icon} /> : null,
          }}
        >
          {!isConfirmed
            ? `${productSku} ${t.admins.upcomingShowsOverview['add anyway']}`
            : t.admins.upcomingShowsOverview['Article added']}
        </Button>
        <Text.Body tag={ETag.DIV} className={styles.disclaimer_text} data-testid="disclaimer-text">
          {!isConfirmed
            ? t.admins.upcomingShowsOverview['Please ensure the item is available at launch time']
            : null}
        </Text.Body>
      </section>
    </div>
  );
};

export default ShowProductReservation;
